'use client'

import { ChipProps } from '@mui/joy'
import { forwardRef } from 'react'
import UIChipRoot from './UIChipRoot'

export interface UIChipProps extends Omit<ChipProps, 'color' | 'variant' | 'size'> {
	color?: 'neutral' | 'point' | 'dark' | 'darkMode'
	size?: 'md' | 'lg' | 'xl'
}

const UIChip = forwardRef<HTMLDivElement, UIChipProps>(({ color, size, ...props }, ref) => {
	return <UIChipRoot ref={ref} ownerState={{ color, size }} {...props} />
})

export default UIChip
