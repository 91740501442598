import { theme } from '@ui/style'

interface Icon32CloseProps {
	color?: string
	className?: string
}
export const Icon32Close = ({ color, className }: Icon32CloseProps) => {
	const colorValue = color || theme.color.colBlack
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			className={className}
		>
			<path d="M8 8L24 24M24 8L8 24" stroke={colorValue} strokeWidth="1.8" />
		</svg>
	)
}
