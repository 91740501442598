'use client'

import { theme } from '@ui/style/theme'

interface Icon16ArrowTinyProps extends SVGComponentProps {
	direction: 'up' | 'down' | 'left' | 'right'
}

const arrowPathByDirection = {
	up: 'M4 10L8 6L12 10',
	down: 'M4 6L8 10L12 6',
	left: 'M10 12L6 8L10 4',
	right: 'M6 12L10 8L6 4',
}

export const Icon16ArrowTiny = ({
	color,
	direction,
	strokeWidth = 1.5,
	className,
}: Icon16ArrowTinyProps) => {
	const iconColor = color || theme.color.colBlack
	const arrowPath = arrowPathByDirection[direction]
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
		>
			<path d={arrowPath} stroke={iconColor} strokeWidth={strokeWidth} />
		</svg>
	)
}
