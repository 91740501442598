import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import nProgress from 'nprogress'

const useSearchFilter = () => {
	const pathname = usePathname()
	const { replace } = useRouter()
	const searchParams = useSearchParams()
	const params = new URLSearchParams(searchParams)

	const handleSearch = (value: string, paramName: string, path?: string) => {
		if (value) {
			params.set(paramName, value)
		} else {
			params.delete(paramName)
		}

		const decodedParams = decodeURIComponent(params.toString())
		const url = `${path ?? pathname}?${decodedParams}`

		nProgress.start()
		replace(url, { scroll: false })
	}

	return {
		pathname,
		params,
		handleSearch,
	}
}

export default useSearchFilter
