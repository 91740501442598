import { Input, InputProps, styled } from '@mui/joy'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'

interface SearchMuiInputProps extends InputProps {
	darkMode?: boolean
}

export const SearchMuiInput = styled(Input, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<SearchMuiInputProps>(({ darkMode }) => ({
	boxShadow: 'none',
	borderRadius: '4px',
	border: `1px solid ${darkMode ? '#303030' : color.colGray3}`,
	paddingInline: 0,
	padding: `${pxToRem(12)} ${pxToRem(12)} ${pxToRem(12)} ${pxToRem(16)}`,
	height: pxToRem(48),
	fontSize: pxToRem(16),
	fontWeight: 500,
	background: darkMode ? color.colBlack : color.colWhite,
	'&::before': {
		display: 'none',
	},
	'.MuiInput-input': {
		color: darkMode ? color.colGray1 : color.colBlack,
		lineHeight: 1,
		'&::placeholder': {
			color: darkMode ? '#444' : color.colBlack,
			fontWeight: 500,
			opacity: darkMode ? 1 : 0.3,
		},
	},
	[theme.breakpoints.up('md')]: {
		borderRadius: '8px',
		padding: `${pxToRem(8)} ${pxToRem(20)} ${pxToRem(8)} ${pxToRem(22)}`,
		height: pxToRem(64),
		fontSize: pxToRem(20),
		lineHeight: pxToRem(28),
	},
}))
