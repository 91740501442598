import { atom, useRecoilState } from 'recoil'

import { useLocalStorage } from './useLocalStorage'
import { TSearchHistroy } from '@components/layout/Header/Search/SearchInput'

export const isHistoryOpenState = atom<boolean>({
	key: 'isHistoryOpenState',
	default: false,
})

const useSearchHistory = () => {
	const [isHistoryOpen, setIsHistoryOpen] = useRecoilState(isHistoryOpenState)
	const [searchHistory, setSearchHistory] = useLocalStorage<TSearchHistroy[]>('searchHistory', [])

	function saveHistory(keyword: string) {
		const date = new Date().toISOString()
		const newSearchHistory = [
			{ keyword, date },
			...searchHistory.filter((item) => item.keyword !== keyword),
		].slice(0, 5)

		setSearchHistory(newSearchHistory)
	}

	function openHistory() {
		if (searchHistory?.length > 0) {
			setIsHistoryOpen(true)
		}
	}

	function closeHistory() {
		setIsHistoryOpen(false)
	}

	function deleteHistory() {
		localStorage.removeItem('searchHistory')
		setIsHistoryOpen(false)
	}

	return {
		searchHistory,
		saveHistory,
		openHistory,
		closeHistory,
		deleteHistory,
		isHistoryOpen,
	}
}

export default useSearchHistory
