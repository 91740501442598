import { RefObject, useEffect } from 'react'

export default function useMousedownOutside(
	ref: RefObject<HTMLDivElement>,
	onClickOutside: (event: MouseEvent) => void,
) {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (!ref || !ref.current) return

			if (ref.current && !ref.current!.contains(event.target as Node)) {
				onClickOutside(event)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, onClickOutside])
}
