import { appInterface } from '@store/isApp'
import { usePathname } from 'next/navigation'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

const useHandleAppRefresh = () => {
	const pathname = usePathname()
	const nativeEvent = useRecoilValue(appInterface)
	const handleAppRefresh = useCallback(
		(value: boolean) => {
			const isDisabledPath = pathname.includes('shorts')
			nativeEvent({
				key: 'setRefreshValue',
				value: {
					enableRefresh: isDisabledPath ? false : value,
				},
			})
		},
		[nativeEvent, pathname],
	)
	return handleAppRefresh
}
export default useHandleAppRefresh
