import { PopularKeywordResponseVo } from '@schemas/non-auth'

declare module '@utils/http/response' {
	interface AppResponseList {
		'/restapi/v1/get/external/jtbc-search/popular-keyword': JtbcSearchPopularKeywordResponse
	}
}

export const jtbcSearchPopularKeywordQueryKey = '@external/jtbc-search/popular-keyword' as const

export type JtbcSearchPopularKeywordReqModel = {
	pageSize?: number
}
export type JtbcSearchPopularKeywordResponse = CommonResponse<PopularKeywordResponseVo[]>
