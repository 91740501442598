'use client'

import { Chip } from '@mui/joy'
import { styled } from '@mui/joy/styles'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'
import { UIChipProps } from '.'

interface OwnerState extends Pick<UIChipProps, 'color' | 'size'> {}

const UIChipRoot = styled(Chip)(({ ownerState }: { ownerState: OwnerState }) => {
	const { color = 'neutral', size = 'md' } = ownerState

	const colorMap = {
		neutral: {
			text: theme.color.colGray5,
			background: theme.color.colGray1,
		},
		point: {
			text: theme.color.colMintBlue,
			background: theme.color.colGray1,
		},
		dark: {
			text: theme.color.colDarkBlue,
			background: theme.color.colLightBlue,
		},
		darkMode: {
			text: theme.color.colGray5,
			background: '#303030',
		},
	}

	const sizeMap = {
		md: {
			py: 6,
			px: 10,
		},
		lg: {
			py: 8,
			px: 12,
		},
		xl: {
			py: 8,
			px: 12,
		},
	}

	const fontMap = {
		md: {
			fontSize: 14,
			lineHeight: 20,
			fontWeight: 600,
		},
		lg: {
			fontSize: 14,
			lineHeight: 22,
			fontWeight: 600,
		},
		xl: {
			fontSize: 16,
			lineHeight: 24,
			fontWeight: 600,
		},
	}

	const styles = {
		borderRadius: pxToRem(4),
		padding: `${pxToRem(sizeMap[size].py)} ${pxToRem(sizeMap[size].px)}`,
		fontSize: pxToRem(fontMap[size].fontSize),
		fontWeight: fontMap[size].fontWeight,
		lineHeight: pxToRem(fontMap[size].lineHeight),
		background: colorMap[color].background,
		color: colorMap[color].text,
		'&': {
			wordBreak: 'break-all',
			whiteSpace: 'normal',
		},
		'&>a': {
			background: 'inherit',
			color: 'inherit',
			'&:hover, &:active': {
				background: 'inherit',
				color: 'inherit',
			},
		},
	}
	return styles
})

export default UIChipRoot
