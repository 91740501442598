import { useEffect, useState } from 'react'
import { useNotification } from '@lib/notification'

const useSearchKeyword = ({ defaultKeyword = '' }: { defaultKeyword?: string }) => {
	const { notify } = useNotification()
	const [keyword, setKeyword] = useState('')

	useEffect(() => {
		if (defaultKeyword) {
			setKeyword(defaultKeyword)
		}
	}, [defaultKeyword])

	function checkKeywordValidation() {
		if (!keyword) {
			notify('검색어를 입력해 주세요!')
			return false
		}
		return true
	}

	function updateKeyword(value: string) {
		setKeyword(value)
	}

	return {
		keyword,
		updateKeyword,
		checkKeywordValidation,
	}
}

export default useSearchKeyword
