import { useQuery } from '@tanstack/react-query'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'
import {
	JtbcSearchPopularKeywordReqModel,
	JtbcSearchPopularKeywordResponse,
	jtbcSearchPopularKeywordQueryKey,
} from './types'

const path = '/restapi/v1/get/external/jtbc-search/popular-keyword'
export const getJtbcSearchPopularKeyword = async (args?: JtbcSearchPopularKeywordReqModel) => {
	const params = new URLSearchParams({
		...(args?.pageSize && { pageSize: String(args.pageSize) }),
	})
	return fetchAPI({ path, params })
}

export const useJtbcSearchPopularKeywordQuery = (
	input?: CommonUseQueryInput<JtbcSearchPopularKeywordResponse, JtbcSearchPopularKeywordReqModel>,
) => {
	const { data, ...rest } = useQuery<JtbcSearchPopularKeywordResponse>({
		queryKey: [jtbcSearchPopularKeywordQueryKey, input?.arg],
		queryFn: async () => {
			const res = await getJtbcSearchPopularKeyword({
				pageSize: input?.arg?.pageSize ?? 10,
			})
			return res
		},
		...input?.opt,
	})
	return { data, ...rest }
}
