import { Icon32Search } from 'src/assets/icons/Icon32Search'

import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'

import UIText from '@components/ui/UIText'
import { Box, ButtonProps } from '@mui/joy'

type SearchButtonProps = ButtonProps & {
	darkMode?: boolean
}

const SearchButton = ({ darkMode, ...rest }: SearchButtonProps) => {
	return (
		<Box
			component="button"
			width={{ xs: pxToRem(24), md: pxToRem(32) }}
			height={{ xs: pxToRem(24), md: pxToRem(32) }}
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{
				'&>svg': {
					width: { xs: pxToRem(18), md: pxToRem(21) },
					height: { xs: pxToRem(18), md: pxToRem(21) },
				},
			}}
			{...rest}
		>
			<Icon32Search color={darkMode ? color.colGray1 : color.colBlack} />
			<UIText readonly>검색</UIText>
		</Box>
	)
}

export default SearchButton
