'use client'

import { Box } from '@mui/joy'

const IconSearchDelete = () => {
	return (
		<Box
			sx={{
				'.mobile': {
					display: { xs: 'block', md: 'none' },
				},
				'.desktop': {
					display: { xs: 'none', md: 'block' },
				},
			}}
		>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="mobile"
			>
				<rect width="24" height="24" />
				<g clipPath="url(#clip0_3688_87360)">
					<rect width="16" height="16" transform="translate(4 4)" fill="white" />
					<circle cx="12" cy="12" r="7.5" fill="#999999" />
					<path d="M8.625 8.625L15.375 15.375" stroke="white" strokeWidth="1.5" />
					<path d="M8.625 15.375L15.375 8.625" stroke="white" strokeWidth="1.5" />
				</g>
				<defs>
					<clipPath id="clip0_3688_87360">
						<rect width="16" height="16" fill="white" transform="translate(4 4)" />
					</clipPath>
				</defs>
			</svg>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="desktop"
			>
				<rect width="24" height="24" />
				<circle cx="12" cy="12" r="10" fill="#999999" />
				<path d="M7.5 7.5L16.5 16.5" stroke="white" strokeWidth="1.8" />
				<path d="M7.5 16.5L16.5 7.5" stroke="white" strokeWidth="1.8" />
			</svg>
		</Box>
	)
}

export default IconSearchDelete
