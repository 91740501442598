'use client'

import dayjs from 'dayjs'

import { TSearchHistroy } from '.'
import { useDarkModeControl } from '@hooks/useDarkModeControl'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import SearchHistoryItem from './SearchHistoryItem'

type Props = {
	data: TSearchHistroy[]
	deleteHistory: () => void
	onClick?: (keyword: string) => void
}

const SearchHistoryList = ({ data, deleteHistory, onClick }: Props) => {
	const { darkMode } = useDarkModeControl()

	return (
		<UIFlex
			border={`1px solid ${darkMode ? '#303030' : color.colGray3}`}
			borderRadius={pxToRem(4)}
			bgcolor={darkMode ? color.colBlack : color.colWhite}
			px={{ xs: pxToRem(16), md: pxToRem(22) }}
			py={{ xs: pxToRem(12), md: pxToRem(16) }}
			sx={{ '&> *:not(:first-child)': { marginTop: { xs: pxToRem(8), md: pxToRem(16) } } }}
		>
			<UIFlex
				flexDirection="row"
				justifyContent="space-between"
				borderBottom={{
					xs: `1px solid ${darkMode ? '#303030' : color.colGray2}`,
					md: 0,
				}}
				pb={{ xs: pxToRem(12), md: 0 }}
			>
				<UIText
					fontSize={{ xs: pxToRem(13), md: pxToRem(14) }}
					lineHeight={pxToRem(22)}
					color={darkMode ? '#D6D6D6' : color.colDarkBlue}
					fontWeight={600}
				>
					최근 검색어
				</UIText>
				<UIText
					component="button"
					fontSize={{ xs: pxToRem(13), md: pxToRem(14) }}
					lineHeight={pxToRem(22)}
					color={color.colGray5}
					fontWeight={500}
					onClick={deleteHistory}
				>
					전체삭제
				</UIText>
			</UIFlex>
			<UIFlex
				sx={{
					'& .searchHistory-item:last-child': {
						borderBottom: 0,
					},
					'&> *:not(:first-child)': { marginTop: pxToRem(8) },
				}}
			>
				{data?.map((item, index) => (
					<SearchHistoryItem
						key={item.date}
						keyword={item.keyword}
						date={
							dayjs(item.date).year() === dayjs().year()
								? dayjs(item.date).format('MM.DD')
								: dayjs(item.date).format('YYYY.MM.DD')
						}
						onClick={() => onClick?.(item.keyword)}
					/>
				))}
			</UIFlex>
		</UIFlex>
	)
}

export default SearchHistoryList
