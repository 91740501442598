'use client'

import { MouseEvent } from 'react'

import { useDarkModeControl } from '@hooks/useDarkModeControl'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import UIButton from '@components/ui/UIButton'

interface SearchHistoryItemProps {
	keyword: string
	date: string
	onClick?: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
}
const SearchHistoryItem = ({ keyword, date, onClick }: SearchHistoryItemProps) => {
	const { darkMode } = useDarkModeControl()

	return (
		<UIFlex
			width="100%"
			flexDirection="row"
			justifyContent="space-between"
			pb={pxToRem(7)}
			borderBottom={`1px solid ${darkMode ? '#303030' : color.colGray2}`}
			className="searchHistory-item"
			sx={{
				'&:last-of-type': {
					pb: 0,
				},
				'&> *:not(:first-child)': { marginLeft: pxToRem(24) },
			}}
		>
			<UIButton variant="text" onClick={onClick} style={{ padding: 0 }}>
				<UIText
					fontSize={{ xs: pxToRem(14), md: pxToRem(15) }}
					lineHeight={pxToRem(22)}
					fontWeight={500}
					flexGrow={1}
					color={darkMode ? color.colGray1 : color.colBlack}
					textAlign="left"
					sx={{
						wordBreak: 'break-all',
					}}
				>
					{keyword}
				</UIText>
			</UIButton>
			<UIText
				fontSize={{ xs: pxToRem(13), md: pxToRem(14) }}
				lineHeight={pxToRem(22)}
				fontWeight={500}
				color={color.colGray5}
				flexShrink={0}
			>
				{date}
			</UIText>
		</UIFlex>
	)
}

export default SearchHistoryItem
