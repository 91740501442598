import { useTheme } from '@mui/joy'

export const Icon32Search = ({ color, className, width = 21, height = 21 }: SVGComponentProps) => {
	const theme = useTheme()
	const iconColor = color || theme.color.colBlack
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g id="Group">
				<circle id="Ellipse 81" cx="8" cy="8" r="7" stroke={iconColor} strokeWidth="1.8" />
				<rect
					id="Rectangle 12515"
					x="12"
					y="13.2728"
					width="1.8"
					height="10"
					transform="rotate(-45 12 13.2728)"
					fill={iconColor}
				/>
			</g>
		</svg>
	)
}
